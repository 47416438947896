import React from "react";
import PImg1 from "../../assets/images/PImg1.png";
import { HeaderPage } from "./HeaderPage";
import { Typography } from "@mui/material";
import { PortfolioButtons } from "./PortfolioButtons";

import { Pmenu } from "./Pmenu";
import { convertImagePathToUrl } from "../../utils";

const formatModalDescription = (description) =>
  description?.replace(/(\r\n|\r|\n)/g, "<br>");

export const Introduction = ({ hero, about, details }) => {
  // console.log("Hero Details: ", hero.details);

  if (hero.length === 0) {
    return;
  }

  const buttonsFromJson = hero.details?.flatMap(
    (detail) =>
      detail.buttons?.map((button) => ({
        id: button.button_id,
        title: button.label,
        action: button.action,
        position: button.button_position,
      })) || []
  );
  return (
    <>
      {/* Hero */}
      <div id="hero" className="z-0 portfolioImg1">
        <div
          className="hidden lg:block"
          style={{ position: "", top: 0, zIndex: 50 }}
        >
          <HeaderPage />
        </div>
        <div className="lg:hidden">
          <Pmenu />
        </div>

        <div
          className="flex flex-col-reverse items-center  py-20 justify-between pt-[7rem] mx-auto text-white 2xl:flex-row"
          style={{ maxWidth: "80%" }}
        >
          <div className="w-full 2xl:w-1/2">
            <Typography
              style={{
                fontSize: 48,
                fontWeight: 600,
                fontFamily: "Montserrat",
              }}
            >
              {hero?.section_heading}
            </Typography>
            {hero?.details.map((detail, index) => (
              <>
                {detail?.singleArray?.map((det, ind) => (
                  <React.Fragment key={ind}>
                    {" "}
                    {det?.key === "sub-heading" && (
                      <>
                        <Typography
                          className="mt-5"
                          style={{
                            fontSize: 25,
                            fontWeight: 500,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {det?.sub_heading}
                        </Typography>
                        <Typography
                          className="mx-auto my-5"
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            fontFamily: "Montserrat",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formatModalDescription(
                              det?.sub_heading_desc
                            ), // Correctly using dangerouslySetInnerHTML here
                          }}
                        />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            ))}
            <div className="mb-10">
              <PortfolioButtons Pbutton1={buttonsFromJson} />
            </div>
          </div>

          <div className="flex items-center justify-center w-full xl:w-1/2 2xl:justify-end">
            {/* <img src={PImg1} /> */}

            {hero?.details?.map((detail, index) => (
              <React.Fragment key={index}>
                {detail?.assets?.map((asset, assetIndex) => (
                  <div key={assetIndex} className="">
                    {asset?.images?.map((img, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={convertImagePathToUrl(img.image_url)}
                        className=""
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      {/* About */}
      <div id="About" className="z-0 flex flex-row portfolioImg2">
        <div
          className="flex flex-row justify-start mx-auto 2xl:flex-row"
          style={{ maxWidth: "80%" }}
        >
          <div className="hidden xl:w-1/2 xl:block">
            {about?.details?.map((detail, index) => (
              <React.Fragment key={index}>
                {detail?.assets?.map((asset, assetIndex) => (
                  <div key={assetIndex} className="py-40 2xl:py-40">
                    {asset?.images?.map((img, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={convertImagePathToUrl(img.image_url)}
                        className="w-[480px]"
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>

          <div className="text-white xl:w-1/2 mt-[5rem]">
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Montserrat",
              }}
            >
              {about?.section_heading}
            </Typography>
            {about?.details?.map((detail) =>
              detail.sub_headings?.map((heading) => (
                <div key={heading.sub_heading_id} className="mb-10">
                  <Typography
                    className=""
                    style={{
                      fontSize: 36,
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                    }}
                  >
                    {heading.sub_heading}
                  </Typography>

                  <Typography
                    className="my-10"
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: formatModalDescription(heading.sub_heading_desc), // Correctly using dangerouslySetInnerHTML here
                    }}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
