import React from "react";
import { TeamLoop2 } from "./TeamLoop2";
import { TeamLoop1 } from "./TeamLoop1";

export const Team = ({ trendTeams }) => {
  // Log the trendTeams data for debugging
  // console.log("TREND Team Data: ", trendTeams);

  // Ensure trendTeams and trendTeams.details are defined and an array
  if (!trendTeams || !Array.isArray(trendTeams.details)) {
    console.error("Invalid trendTeams data:", trendTeams);
    return null; // Return null if the data is invalid
  }

  return (
    <div id="TREND Team">
      {trendTeams.details.map((detail, index) => {
        const isLastItem = index === 3;

        return index % 2 === 0 ? (
          <TeamLoop1
            Loop1={detail?.singleArray}
            isLastItem={isLastItem}
            key={index}
          />
        ) : (
          <TeamLoop2
            Loop2={detail?.singleArray}
            isLastItem={isLastItem}
            key={index}
          />
        );
      })}
    </div>
  );
};
