import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../../assets/images/Polygon.svg";
import { convertImagePathToUrl } from "../../utils";

import React from "react";
import { Typography } from "@mui/material";

export const AuthorCrousal = ({ author }) => {
  const subHeadings = author?.details?.[0]?.sub_headings || [];
  // console.log("Sub-Headings: ", subHeadings);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="py-10">
      <Slider {...settings}>
        {subHeadings.map((value, index) => (
          <div
            className="relative inline-block 2xl:my-10 w-80 md:w-1/2 mobPolygon"
            style={{
              backgroundImage: "url(value?.sub_heading_bg_image)",
            }}
          >
            <Typography
              className="mx-10 mt-20 text-center text-white sm:mt-36 sm:mx-5"
              style={{
                fontFamily: "Montserrat",
                fontSize: 20,
                fontWeight: "bolder",
              }}
            >
              {value?.sub_heading}
            </Typography>
            {/* <Typography
              className="mx-10 mt-5 text-center text-white sm:mx-5 sm:mt-5"
              style={{ fontFamily: "Montserrat", fontSize: 16 }}
            >
              {value?.sub_heading_desc}
            </Typography> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};
