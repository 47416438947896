import { Typography, Button } from "@mui/material";
import React from "react";

export const PortfolioButtons = ({ Pbutton1 }) => {
  const handleClick = (value) => {
    if (value.title === "Support Us") {
      window.location.href = value.action; // Redirect to the backend route
    } else {
      const element = document.getElementById(value.action);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="flex flex-col items-center xl:flex-row">
      {Pbutton1.sort((a, b) => a.position - b.position) // Sort based on button_position
        .map((value) => (
          <Button
            key={value.title}
            onClick={() => handleClick(value)}
            style={{
              borderColor: "#FFFFFF",
              backgroundColor:
                value.title === "Support Us" ? "#3778A5" : "transparent",
            }}
            className="flex justify-center w-40 h-12 mt-5 mr-5 text-white border-2 border-solid rounded-lg 2xl:w-[11rem] 2xl:h-12"
          >
            <Typography
              className="text-[14px]"
              style={{ textTransform: "none" }}
            >
              {value.title}
            </Typography>
          </Button>
        ))}
    </div>
  );
};
