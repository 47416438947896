import { Button, Typography, Modal, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AuthorCrousal } from "./AuthorCrousal";
import { convertImagePathToUrl } from "../../utils";

export const AuthorPolygon = ({ author }) => {
  // console.log(author);

  const [firstThree, setFirstThree] = useState([]);
  const [secondThree, setSecondThree] = useState([]);
  const [thirdThree, setThirdThree] = useState([]);

  // State for modal visibility and content
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    heading: "",
    description: "",
    image: "",
    modal_image: "",
  });

  useEffect(() => {
    const subHeadings = author?.details?.[0]?.sub_headings || [];
    if (subHeadings.length > 0) {
      setFirstThree(subHeadings.slice(0, 3));
      setSecondThree(subHeadings.slice(3, 6));
      setThirdThree(subHeadings.slice(6, 9));
    }
  }, [author]);

  const handleOpenModal = (value) => {
    setModalContent({
      heading: value?.sub_heading,
      description: value?.sub_heading_desc,
      image: convertImagePathToUrl(value?.sub_heading_bg_image),
      modal_image: convertImagePathToUrl(value?.sub_heading_image),
    });
    setOpenModal(true);
  };

  const formatModalDescription = (description) =>
    description?.replace(/(\r\n|\r|\n)/g, "<br>");

  const handleCloseModal = () => setOpenModal(false);

  const viewMoreButton = author?.details?.[0]?.buttons?.[0];

  return (
    <div>
      {/* Main content */}
      <div>
        <div className="flex flex-col items-center justify-center px-10 pt-20">
          <Typography
            className="text-white"
            style={{
              fontSize: 25,
              fontFamily: "Montserrat",
              fontWeight: "bolder",
            }}
          >
            {author?.section_heading}
          </Typography>
          <Typography
            className="px-10 text-white"
            style={{
              fontSize: 14,
              fontFamily: "Montserrat",
            }}
          >
            {author?.section_description}
          </Typography>
        </div>
      </div>
      <div className="lg:hidden">
        <AuthorCrousal author={author} />
      </div>

      {/* Displaying firstThree, secondThree, and thirdThree in flex-row */}
      <div className="flex flex-col items-center justify-between hidden mx-10 mt-20 space-y-10 md:hidden lg:block xl:block 2xl:block md:flex-col 2xl:mx-44">
        {[firstThree, secondThree, thirdThree].map((group, index) => (
          <div
            className="flex flex-row justify-between w-full space-x-10"
            key={index}
          >
            {group.map((value, index) => (
              <a
                className={`cursor-pointer ${index === 1 ? "pt-20" : ""}`}
                key={value?.id}
                onClick={() => handleOpenModal(value)} // On click, open modal
                style={{ textDecoration: "none" }}
                data-aos="fade-in"
                data-aos-delay="500"
              >
                <div
                  className="flex flex-col justify-center my-2 2xl:my-10 polygon"
                  style={{
                    backgroundImage: `url(${convertImagePathToUrl(
                      value?.sub_heading_bg_image
                    )})`,
                  }}
                >
                  <div
                    className="py-4 text-center"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                      backdropFilter: "blur(10px)", // Apply blur effect
                      WebkitBackdropFilter: "blur(10px)", // Ensure compatibility with Safari
                    }}
                  >
                    <Typography
                      className="px-20"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        fontWeight: 700,
                        color: "#FFFFFF",
                        opacity: 1.0,
                      }}
                    >
                      {value?.sub_heading}
                    </Typography>
                  </div>
                </div>
              </a>
            ))}
          </div>
        ))}
      </div>

      {/* Modal for displaying item details */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          className="flex flex-col items-center justify-between px-20 py-10 overflow-y-hidden"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "1320px",
            maxHeight: "95vh",
            borderRadius: "30px",
            boxShadow: 24,
            overflowY: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(10px)",
          }}
        >
          <button
            className="absolute text-4xl text-white bg-transparent cursor-pointer top-3 right-3"
            onClick={handleCloseModal}
          >
            &times;
          </button>
          <div className="flex flex-col w-full gap-5 lg:flex-row">
            <div id="first" className="lg:w-[40%] mt-10 mb-4 lg:mb-0">
              <img
                src={modalContent.modal_image}
                alt={modalContent.heading}
                style={{
                  width: "415px",
                  height: "375px",
                  marginBottom: "20px",
                }}
              />
              <Typography
                variant="h6"
                style={{
                  marginBottom: "20px",
                  color: "white",
                  fontWeight: 700,
                  fontSize: "24px",
                  width: "300px",
                  fontFamily: "Montserrat",
                }}
              >
                {modalContent.heading}
              </Typography>
            </div>
            {/* Scrollable description section */}
            <div
              id="second"
              className="my-6 lg:w-[60%]"
              style={{
                maxHeight: "100%", // Set a fixed height for the description area
                // overflowY: "auto", // Enable scrolling when content exceeds the max height
                paddingRight: "10px", // Add some padding to avoid overlap with the scrollbar
                paddingBottom: "10px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins, serif",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#FFFFFF",
                }}
                dangerouslySetInnerHTML={{
                  __html: formatModalDescription(modalContent.description),
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      <div className="flex items-center justify-center pb-20">
        {viewMoreButton && (
          <div className="flex items-center justify-center pb-20">
            <a
              href={viewMoreButton?.action}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                style={{ borderColor: "#FFFFFF" }}
                className="flex justify-center w-40 h-12 mt-5 mr-5 text-white bg-transparent border-2 border-solid rounded-lg portfolioButton 2xl:w-40"
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    textTransform: "none",
                  }}
                >
                  {viewMoreButton?.label}
                </Typography>
              </Button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
