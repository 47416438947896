import { Typography } from "@mui/material";
import React from "react";

export const Trends = ({ trendTrends }) => {
  const formatModalDescription = (description) =>
    description?.replace(/(\r\n|\r|\n)/g, "<br>");

  // console.log(trendTrends);
  if (!trendTrends || !trendTrends[0] || !trendTrends[0].sub_headings) {
    return null; // or a loading indicator if data is being fetched
  }

  const sub_headings = trendTrends[0].sub_headings;

  return (
    <div>
      <div id="TREND Trends">
        <Typography
          className="flex justify-center text-white xl:py-32"
          style={{
            fontSize: 36,
            fontWeight: 400,
            fontFamily: "Montserrat",
          }}
        >
          TREND Trends
        </Typography>
      </div>

      <div
        className="flex flex-col justify-center mx-auto xl:flex-row"
        style={{ maxWidth: "80%" }}
      >
        {/* First Column */}
        <div className="text-white xl:w-1/2">
          <ul className="pl-5 list-disc">
            {sub_headings
              .slice(0, Math.ceil(sub_headings.length / 2))
              .map((subHeading, index) => (
                <a
                  href={subHeading?.sub_heading_url || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <li
                    key={subHeading.sub_heading_id}
                    className={index > 0 ? "mt-10" : ""}
                  >
                    <Typography
                      style={{ fontSize: 14, fontFamily: "Montserrat" }}
                      dangerouslySetInnerHTML={{
                        __html: formatModalDescription(
                          subHeading?.sub_heading_desc
                        ), // Correctly using dangerouslySetInnerHTML here
                      }}
                    />
                    <Typography
                      className="mt-2 text-blue-300"
                      style={{ fontSize: 14, fontFamily: "Montserrat" }}
                    >
                      <u>Read More Here</u>
                    </Typography>
                  </li>
                </a>
              ))}
          </ul>
        </div>

        {/* Second Column */}
        <div className="mt-10 text-white xl:w-1/2 xl:mt-0 xl:ml-10">
          <ul className="pl-5 list-disc">
            {sub_headings
              .slice(Math.ceil(sub_headings.length / 2))
              .map((subHeading, index) => (
                <a
                  href={subHeading?.sub_heading_url || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <li
                    key={subHeading.sub_heading_id}
                    className={index > 0 ? "mt-10" : ""}
                  >
                    <Typography
                      style={{ fontSize: 14, fontFamily: "Montserrat" }}
                      dangerouslySetInnerHTML={{
                        __html: formatModalDescription(
                          subHeading?.sub_heading_desc
                        ), // Correctly using dangerouslySetInnerHTML here
                      }}
                    />
                    <Typography
                      className="mt-2 text-blue-300"
                      style={{ fontSize: 14, fontFamily: "Montserrat" }}
                    >
                      <u>Read More Here</u>
                    </Typography>
                  </li>
                </a>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
