import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { PolygonLoop } from "./PolygonLoop";
import { PubCrousal } from "./PubCrousal";

import "../../assets/css/scientist.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const KeyPublications = ({ publications, polygon }) => {
  // console.log("Publications: ", publications);
  // console.log("Polygon: ", polygon);
  const [firstThree, setFirstThree] = useState([]);
  const [secondThree, setSecondThree] = useState([]);
  const [thirdThree, setThirdThree] = useState([]);
  const [subHeadings, setSubHeadings] = useState([]);

  const viewMoreButton = publications?.details?.[0]?.buttons?.[0];

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      offset: 500,
    });

    const subHeadings = publications?.details?.[0]?.sub_headings || [];
    setSubHeadings(subHeadings);
    if (subHeadings.length > 0) {
      setFirstThree(subHeadings.slice(0, 3));
      setSecondThree(subHeadings.slice(3, 6));
      setThirdThree(subHeadings.slice(6, 9));
    }
  }, [publications]);

  useEffect(() => {}, [firstThree, secondThree, thirdThree]);

  return (
    <div>
      <div>
        <Typography
          className="pt-20 text-center text-white"
          style={{ fontSize: 30, fontWeight: "bold" }}
        >
          {publications?.section_heading}
        </Typography>
      </div>
      <div className="lg:hidden ">
        <PubCrousal subHeadings={subHeadings} />
      </div>
      <div className="flex flex-col justify-between mx-10 md:flex-row 2xl:mx-44">
        {[firstThree, secondThree, thirdThree].map((group, index) => (
          <div key={index} className="hidden lg:block">
            <PolygonLoop
              polygon={group}
              publications={group}
              customClass={index === 1 ? "pt-20" : ""}
            />
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center pb-20">
        {viewMoreButton && (
          <div className="flex items-center justify-center pb-20">
            <a
              // href="https://scholar.google.com/citations?user=1nlR6iYAAAAJ&hl=en"
              href={viewMoreButton.action}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                style={{ borderColor: "#FFFFFF" }}
                className="flex justify-center w-40 h-12 mt-5 mr-5 text-white bg-transparent border-2 border-solid rounded-lg portfolioButton 2xl:w-40"
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    textTransform: "none",
                  }}
                >
                  {viewMoreButton.label}
                </Typography>
              </Button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
