import React from "react";
import { SupportIntro } from "./SupportIntro";
import { TrendFooter } from "../trendLab/TrendFooter";
import { SupportFooter } from "./SupportFooter";
import { Footer } from "../Footer";

export const SupportHome = () => {
  return (
    <div>
      <SupportIntro />
      <Footer color="#204350" />
    </div>
  );
};
