import React, { useEffect, useState } from "react";
import { TrendNewsCrousal } from "./TrendNewsCrousal";
import { Trends } from "./Trends";
import { TrendThemesPolygon } from "./TrendThemesPolygon";
import ThemePolygon from "../../assets/images/ThemePolygon.png";
import { Typography } from "@mui/material";

export const TrendThemes = ({ trendThemes, trendTrends, trendNews }) => {
  // console.log("Trend Themes: ", trendThemes);
  const [themeData, setthemeData] = useState([]);
  // console.log("Theme Data: ", themeData);
  const mergeObjectsByPosition = (inputArray) => {
    const mergedArray = [];
    // console.log("input", inputArray);

    // Iterate over each item in the input array
    inputArray.forEach((item) => {
      if (item.key === "sub-heading") {
        mergedArray.push({
          title: item.sub_heading,
          description: "",
          position: item.sub_heading_position,
          desc: item.sub_heading_desc,
        });
      } else if (item.key === "desc") {
        const matchingObject = mergedArray.find(
          (obj) => obj.position === item.description_position
        );
        if (matchingObject) {
          matchingObject.description = item.description;
        }
      }
    });
    // console.log(" merge", mergedArray);
    setthemeData(mergedArray);
  };
  useEffect(() => {
    trendThemes?.details?.map((detail) => {
      mergeObjectsByPosition(detail?.singleArray);
    });
  }, [trendThemes]);
  // const person1 = [
  //   {
  //     id: 0,
  //     title: "Epigenetic Propagation of Childhood Trauma",
  //     description1:
  //       "This project delves into the intricate intersection of neuroscience, epigenetics, and metabolism, utilizing a variety of research tools including immortalized cell lines, stem cells, mice models, and human cohorts. Building upon a mouse model of early life trauma established by Prof. Isabelle Mansuy (Ali’s PhD and post-doc supervisor), the project explores the transmission of neuropsychiatric and metabolic disturbances across multiple generations of mice exposed to trauma early in life. Ali’s research has extended these findings to humans, identifying molecular changes suggestive of similar transmission patterns in the offspring of individuals exposed to early life trauma. Collaborations with The SOS Children’s Villages, Pakistan, and Chughtai Labs (Pvt) Limited enrich the project’s scope and impact.",
  //     description2: "",
  //     image: ThemePolygon,
  //   },
  //   {
  //     id: 1,
  //     title:
  //       "Metabolism-Based Strategies for Treatment of Neurodegenerative Disorder",
  //     description1:
  //       "Drawing upon groundbreaking discoveries made during Ali’s tenure with Prof. Paul Schulz at Baylor College of Medicine, Houston, US, and the University of Texas Health Science Center, Houston, this project explores unconventional approaches to treating amyotrophic lateral sclerosis (ALS) and frontotemporal dementia (FTD). Clinical studies conducted by Jawaid et al. revealed that individuals with a conventionally ‘risky’ metabolic profile—such as diabetes mellitus type 2, obesity, or dyslipidemia—exhibit slower clinical progression of ALS and FTD. These findings, validated by independent studies, inspire TREND Lab to unravel the molecular mechanisms underlying these observations.",
  //     description2: "",
  //     image: ThemePolygon,
  //   },
  //   {
  //     id: 1,
  //     title: "Molecular Cascades Involved in Post-COVID Brain Fog",
  //     description1:
  //       "Cofondatrice et PrésidenteAs mounting evidence links COVID-19 to long-term neurobehavioral symptoms, including difficulty focusing, memory deficits, fatigue, depression, anxiety, and sleep disturbances—collectively termed ‘post-COVID Brain Fog’—TREND Lab has shifted focus to elucidating the molecular pathways involved in this phenomenon. By unraveling the underlying mechanisms, the lab aims to contribute to our understanding of COVID-19’s neurological sequelae and pave the way for targeted interventions to alleviate post-COVID Brain Fog.",
  //     description2: "",
  //     image: ThemePolygon,
  //   },
  // ];
  return (
    <div id="TREND Themes" className="TrendTreatBg">
      <Typography
        className="flex justify-center pt-20 text-white xl:py-32"
        style={{
          fontSize: 36,
          fontWeight: 400,
          fontFamily: "Montserrat",
        }}
      >
        {trendThemes?.section_heading}
      </Typography>
      <div className="flex flex-col items-center justify-center xl:flex-row">
        {themeData?.map((person) => (
          <TrendThemesPolygon
            key={person.id}
            trendThemes={trendThemes}
            item={person}
          />
        ))}
      </div>
      <Trends trendTrends={trendTrends.details} />
      <TrendNewsCrousal trendNews={trendNews.details} />
    </div>
  );
};
