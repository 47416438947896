import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logoImg from "../../assets/images/logoimg.svg";

export const PageHeader = () => {
  const navigate = useNavigate();

  const handleConnectClick = () => {
    // Navigate to the /portfolio route
    navigate("/portfolio");

    // Scroll to the element with id "LetsConnect" after navigating
    setTimeout(() => {
      const targetElement = document.getElementById("Lets Connect");
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100); // Delay to ensure navigation is completed before scrolling
  };

  return (
    <div className="flex flex-row items-center justify-between pt-10 head">
      <img src={logoImg} alt="logImg" className="w-40 md:w-52"></img>
      <div className="Button lg:mt-0">
        <Button
          style={{ backgroundColor: "white" }}
          className="h-10 rounded-lg w-36"
          onClick={handleConnectClick}
        >
          <Typography className="text-black" style={{ fontSize: 14 }}>
            Lets Connect
          </Typography>
        </Button>
      </div>
    </div>
  );
};
