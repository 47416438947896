import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import "../../assets/css/scientist.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const PolygonLoop = ({ polygon, publications, customClass }) => {
  // console.log("Publications: ", publications);
  // console.log("Polygon: ", polygon);
  const formatModalDescription = (description) =>
    description?.replace(/(\r\n|\r|\n)/g, "<br>");

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      offset: 500,
    });
  }, []);

  return (
    <div className={`flex flex-col my-10 ${customClass}`}>
      {polygon.map((value) => (
        <a
          className="cursor-pointer"
          key={value.id}
          href={value.sub_heading_url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          data-aos="fade-in"
          data-aos-delay="500"
        >
          <div className="flex flex-col justify-center my-2 2xl:my-10 polygon">
            <Typography
              className="mx-3 text-center text-white"
              style={{
                fontFamily: "Montserrat",
                fontSize: 18,
                fontWeight: "bolder",
              }}
            >
              {value?.sub_heading}
            </Typography>
            <Typography
              className="mx-3 mt-3 text-center text-white 2xl:mt-10"
              style={{ fontFamily: "Montserrat", fontSize: 14 }}
              dangerouslySetInnerHTML={{
                __html: formatModalDescription(value?.sub_heading_desc), // Correctly using dangerouslySetInnerHTML here
              }}
            />
          </div>
        </a>
      ))}
    </div>
  );
};
