import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router";

const menu = [
  { id: 0, title: "Home", section_id: "/" },
  { id: 1, title: "About", section_id: "About" },
  { id: 2, title: "The Scientist", section_id: "The Scientist" },
  { id: 3, title: "The Educator", section_id: "The Educator" },
  { id: 4, title: "The Humanitarian", section_id: "The Humanitarian" },
  { id: 5, title: "The Author", section_id: "The Author" },
  { id: 6, title: "The Buzz", section_id: "The Buzz" },
];

export const Pmenu = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleListItemClick = (section_id) => {
    if (section_id === "/") {
      navigate("/"); // Navigate to home route
    } else {
      const element = document.getElementById(section_id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 300); // Delay to allow drawer to close
      } else {
        console.error("Element not found for section: ", section_id);
      }
    }
  };

  const DrawerList = (
    <Box
      sx={{ backgroundColor: "#001728", width: 250, height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List className="text-white">
        {menu.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              onClick={() => handleListItemClick(item.section_id)}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};
