import React, { useEffect } from "react";
import { ScientistBtn } from "./ScientistBtn";
import { Typography } from "@mui/material";
import HumantarianImg3 from "../../assets/images/HumantarianImg3.png";
import { EduCrousal } from "./EduCrousal";
import ScientistImg6 from "../../assets/images/ScientistImg6.png";

import "aos/dist/aos.css";
import { convertImagePathToUrl } from "../../utils";

const formatModalDescription = (description) =>
  description?.replace(/(\r\n|\r|\n)/g, "<br>");

export const Educator = ({ educators, advocacy }) => {
  const btn1 = [{ id: 0, title: "Reach Out" }];

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".aos-init");

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const triggerPoint = window.innerHeight / 2;

        if (rect.top < triggerPoint) {
          el.style.transitionDuration = "0.2s";
          el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 1, 2)";
          el.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (educators?.length === 0 && advocacy?.length === 0) return;

  return (
    <div>
      <div
        id="The Educator"
        className="portfolioImg6"
        style={{ overflowX: "hidden", overflowY: "hidden" }}
      >
        <div
          className="flex justify-between"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div className="flex justify-end hidden lg:block" style={{ flex: 1 }}>
            <img
              src={HumantarianImg3}
              className="h-full ml-auto leftArrowStyle"
            />
          </div>
          <div
            className="flex flex-col items-center "
            style={{ flex: 2, background: "#EBEEF3" }}
          >
            <div className="py-10 mx-20 lg:mx-0" style={{ maxWidth: "580px" }}>
              <Typography
                className="pb-5"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 36,
                  fontWeight: 400,
                }}
              >
                {educators?.section_heading}
              </Typography>
              {educators?.details?.map((detail, index) => (
                <>
                  {detail?.singleArray?.map((det, ind) => (
                    <>
                      {det?.key === "sub-heading" && (
                        <Typography
                          key={index}
                          className="mt-5"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {det?.sub_heading}
                        </Typography>
                      )}

                      {det?.key === "sub-heading" && (
                        <Typography
                          key={"desc"}
                          className="mx-auto my-5"
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: "Montserrat",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formatModalDescription(
                              det?.sub_heading_desc
                            ), // Correctly using dangerouslySetInnerHTML here
                          }}
                        />
                      )}

                      {det?.key === "btt" && (
                        <div className="flex justify-start pb-">
                          <ScientistBtn Sbutton1={det?.childButtons} />
                        </div>
                      )}
                    </>
                  ))}
                </>
              ))}
            </div>
          </div>
          <div
            className="flex justify-end hidden object-contain lg:block "
            style={{ flex: 1, background: "#EBEEF3" }}
          >
            {educators?.details?.map((detail, index) => (
              <React.Fragment key={index}>
                {detail?.assets?.map((asset, assetIndex) => (
                  <div key={assetIndex} className="">
                    {asset?.images?.map((img, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={convertImagePathToUrl(img.image_url)}
                        // src="../../assets/images/image.png"
                        // className="object-contain w-11/12 ml-auto leftArrowStyle xl:h-full xl:py-10 "
                        className="object-contain max-w-[434px] max-h-[641px] mt-20 leftArrowStyle"
                        style={{ background: "#EBEEF3" }}
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="pt-20">
          <EduCrousal advocacy={advocacy} />
        </div>
      </div>
    </div>
  );
};
