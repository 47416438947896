export const convertImagePathToUrl = (imagePath) => {
  // Convert all backslashes (single or double) to forward slashes
  if (!imagePath) {
    return;
  }

  const convertedPath = imagePath.replace(/\\\\?/g, "/");
  // Prepend the base URL to the path
  const url = `${process.env.REACT_APP_API_URL}/storage/${convertedPath}`;
  // console.log("Converted URL: ", url); // Debugging line
  return url;
};
