import { createBrowserRouter, Route } from "react-router-dom";
import { LandingPage } from "../pages/LandingPage";
import { Portfolio } from "../pages/Portfolio";
import { TrendLab } from "../pages/TrendLab";
import { SupportUs } from "../pages/SupportUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    path: "/trendlab",
    element: <TrendLab />,
  },
  {
    path: "/supportus",
    element: <SupportUs />,
  },
]);

export default router;
