import { Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ScientistBtn = ({ Sbutton1 }) => {
  const navigate = useNavigate();

  // For routing inside the web
  const handleClick = (url) => {
    navigate(url);
  };

  // For page sections navigation buttons
  const handleClick1 = (title) => {
    const element = document.getElementById(title);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-row">
      {Sbutton1.map((value) => {
        if (value.label === "Discover More") {
          // Use an <a> tag for "Discover More"
          return (
            <a
              key={value.button_id}
              href={value.action}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center w-40 h-12 p-0 my-5 text-black bg-white border-2 border-black border-solid rounded-lg portfolioButton"
            >
              <Typography
                className="pt-3"
                style={{
                  textTransform: "none",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                }}
              >
                {value.label}
              </Typography>
            </a>
          );
        } else {
          // Use a Button for other cases
          return (
            <Button
              key={value.button_id}
              onClick={
                value.label === "Reach Out"
                  ? () => handleClick1(value.action)
                  : () => handleClick(value.action)
              }
              className="flex justify-center w-40 h-12 p-0 my-5 text-black bg-white border-2 border-black border-solid rounded-lg portfolioButton"
            >
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                }}
              >
                {value.label}
              </Typography>
            </Button>
          );
        }
      })}
    </div>
  );
};
