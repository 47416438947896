import { Typography } from "@mui/material";
import React from "react";

import { SeminarsCrousal } from "./SeminarsCrousal";

export const TrendSeminars = ({ trendSeminar }) => {
  // console.log("TrendSeminar: ", trendSeminar);
  return (
    <div>
      <div className="flex flex-col items-center justify-center px-10 pt-32">
        <Typography
          className="text-white "
          style={{
            fontSize: 36,
            fontFamily: "Montserrat",
            fontWeight: 400,
          }}
        >
          TREND Seminars
        </Typography>
        <Typography
          className="px-10 pt-10 text-white "
          style={{
            fontSize: 14,
            fontFamily: "Montserrat",
          }}
        >
          Lorem ipsum dolor sit amet, adipiscing elit. Duis pretium. Lorem ipsum
        </Typography>
        <Typography
          className="px-10 text-white "
          style={{
            fontSize: 14,
            fontFamily: "Montserrat",
          }}
        >
          consectetur adipiscing elit.
        </Typography>
      </div>
      <div>
        <SeminarsCrousal trendSeminar={trendSeminar} />
      </div>
    </div>
  );
};
